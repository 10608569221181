import { useState } from 'react';
import {  StyleSheet,  } from 'react-native';
import NuevoUsuario from '../Camara/NuevoUsuario';

import { StatusBar } from 'expo-status-bar';
import { AuthProvider } from '../../AuthContext';
import { useColorScheme } from 'react-native';








const Rooteo = ({navigation}) => {
    const colorScheme = useColorScheme();
    const [view, setView] = useState("NuevoUsuario");

    const renderView = () => {




        switch (view) {
          
             case "NuevoUsuario":
               return <AuthProvider><NuevoUsuario setView={setView} ></NuevoUsuario></AuthProvider>
                
                
        }
    }


    return (
        <>

            {renderView()}
        </>
    )



}

export default Rooteo;

const styles = StyleSheet.create({
    container: {
        flex: 1,
        backgroundColor: '#fff',
        alignItems: 'center',
        justifyContent: 'center',
    }
})