import React, { useState, useEffect } from 'react';
import { View, Text, StyleSheet, TextInput } from 'react-native';

const MyInput = ({ value, onValueChange, inputType = 'text' }) => {


    const [textValue, setTextValue] = useState(value);



    const handleValueChange = (newValue) => {
        // Ejecuta la función de devolución de llamada cuando cambia el valor
        setTextValue(newValue);
        onValueChange(newValue);
    };


    // Establece el valor del estado local cuando el valor externo cambia
    useEffect(() => {
        setTextValue(value);
    }, [value]);


    return (
        <View style={styles.container}>
            <TextInput
                style={styles.inputText}
                value={textValue}
                onChangeText={handleValueChange}
                keyboardType={inputType === 'numeric' ? 'numeric' : 'default'}
            ></TextInput>
        </View>
    );
};

const styles = StyleSheet.create({
    container: {
        width: '100%', // Ajusta para ocupar el 100% del ancho disponible
        backgroundColor: '#fff',
        padding: 5,
        marginTop: 10,

    },
    inputText: {
        backgroundColor: '#F5F5F6',
        paddingVertical: 10,
        paddingHorizontal: 15.5,
        height: 100,
        borderRadius: 10,
        fontWeight: '900',
        fontSize: 50,
        textAlign: 'center',
        color: '#37AF68',
        borderColor: '#37AF68',
        borderWidth: 2,
    },


});

export default MyInput;
