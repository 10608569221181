import React from 'react';
import { TouchableOpacity, Text, StyleSheet, View } from 'react-native';

const Boton = ({onPress, text, bg, cl}) => {
  return (
    <TouchableOpacity style={[styles.button, {backgroundColor:bg}]} onPress={onPress}>
      <Text style={[styles.buttonText,{ color: cl}]}>{text}</Text>
    </TouchableOpacity>
  );
}

export default Boton;

const styles = StyleSheet.create({
  button: {
   elevation: 3, // Ajusta el valor según el nivel de sombra deseado
    shadowColor: '#000000', // Color de la sombra
    shadowOpacity: 0.3, // Opacidad de la sombra (0 - 1)
    shadowRadius: 2, // Radio de la sombra
    shadowOffset: {
      width: 0, // Desplazamiento horizontal de la sombra
      height: 2, // Desplazamiento vertical de la sombra
    },
    padding: 10,
    borderRadius:5,
    alignItems: 'center',
    width:"100%",
    justifyContent: 'center',
    
  },
  buttonText: {
    //fontFamily: 'Gabarito',
    fontSize: 16,
  }
});
