import { StatusBar } from 'expo-status-bar';
import React, { useRef, useState, useEffect } from 'react';
import { View, Text, Button, ActivityIndicator, TouchableOpacity, StyleSheet } from 'react-native';
import { useNavigation, useFocusEffect } from '@react-navigation/native';
import WebView from 'react-native-webview';
import * as FileSystem from 'expo-file-system';
import * as MediaLibrary from 'expo-media-library';
import MyInputWhatsApp from './src/components/Form/MyInputWhatsApp';

import TelefonoArgentino from './src/validateMobile';
import Boton from './src/components/Form/Boton';
import MyLoading from './src/components/Form/MyLoading';
import { set } from 'date-fns';
import postWhatsApp from './src/components/Calls/postWhatsApp';
import MyTextDynamic from './src/components/Form/MyTextDynamic';

import { NavigationContainer } from '@react-navigation/native';
import Rooteo from './src/components/Vistas/Rooteo';



 import { AlertNotificationRoot} from 'react-native-alert-notification';

const   App=()=> {


  const [iptNumeroWhatsapp, setIptNumeroWhatsapp] = useState("+54 9 ");
  const [successWhatsapp, setSuccessWhatsapp] = useState(0);

  const [nroSocio, setNroSocio] = useState('');
  const [pdfUri, setPdfUri] = useState("");


  const [type, setType] = useState(1);

  const [loading, setLoading] = useState(false);

  const set_IptNumeroWhatsapp = (value) => {


      //setIptNumeroWhatsapp(value);
      if (value.length > 0) {
          formatPhoneNumber(value);
      }

  }





  const formatPhoneNumber = (number) => {



      //console.warn(tel.data.format);

      if (number != "") {


          if (number == "+54 9 ") {

              setSuccessWhatsapp(0);
          } else {






              const tel = new TelefonoArgentino(number);

              let telSin = number.replace("+54 9 ", "").replace(" ", "");



              if (telSin.length >= 9) {



                  if (tel.data) {
                      setIptNumeroWhatsapp(tel.data.format.replace("-", ""));
                      setSuccessWhatsapp(2);
                  } else {

                      setSuccessWhatsapp(1);

                  }
              } else {

                  setSuccessWhatsapp(1);
              }



          }



      }


  }


  const getDatosNroSocio = async (NroSocio) => {
      const data = `cadena=%7B%22MembershipNumber%22%3A%22${String(NroSocio)}%22%7D`;

      try {
          const response = await fetch("https://coovilros.com/ws2/service1.asmx/GetSocioByNroSocio", {
              method: "POST",
              headers: {
                  "Content-Type": "application/x-www-form-urlencoded",
                  "Cookie": ".ASPXAUTH=9495059B14D889D4B3CDC839F5063A996D595C315B10D381455CD9D321172D7E61BA46C1DCBB2CFB8477F3B953542B583A177F9A9CA68574C09E57A2FB2EA27E3D3A42E7B8910750D30EAE8B03EBA2B8BE14C61B71C6CBE8B10EB0CDDAEE0DC962D9124EA282C6D1BDC808437F396DC89CAB260D943FCF29BA96F30659AB74297F30F4672E16722DB7BBCC636F2EC51D3E1C33F4CCAC3B251E2F3AF6800F6E22",
              },
              body: data,
          });

          if (response.ok) {
              const xmlString = await response.text();
              const jsonString = xmlString.match(/<string[^>]*>([^<]*)<\/string>/)[1];
              const jsonObject = JSON.parse(jsonString);

              if (jsonObject.Status === "200") {
                  return jsonObject;
              } else {
                  return false;
              }
          } else {
              // Manejar errores de la solicitud, por ejemplo, response.status no es 200
           //   console.error(`Error en la solicitud: ${response.status}`);
              return false;
          }
      } catch (error) {
          // Manejar errores en caso de una excepción
//console.error("Error en la solicitud:", error);
          return false;
      }
  };



  const getPdf = async () => {

      const data = await getDatosNroSocio(nroSocio)


      if (data == false) {

      } else {
          setType(2);




          const url = data.NroResumen;

          // Dividir la URL por el carácter "?"
          const parts = url.split('?');

          // Obtener la segunda parte después del "?"
          const secondPart = parts[1];

          // Dividir nuevamente por el signo "="
          const values = secondPart.split('=');

          // Obtener el segundo elemento, que es el valor que buscas
          const desiredValue = values[1];


          let urlResumen = 'https://www.coovilros.com/DescargarPDf.aspx?nroresumen=' + desiredValue;





          setPdfUri(urlResumen);
      }

  }






  const write = (number) => {
      

      if (type == 1) {

          if (nroSocio.length >= 7) {
              // Si es más largo o igual a 7, eliminar el último carácter
              setNroSocio((prevNroSocio) => prevNroSocio.slice(0, -1));
          }


          setNroSocio((prevNroSocio) => prevNroSocio + number.toString());


      } else {
          setIptNumeroWhatsapp((prevValue) => prevValue + number.toString());
          const newPhoneNumber = iptNumeroWhatsapp + number.toString();
          set_IptNumeroWhatsapp(newPhoneNumber);

      }



  }

  const deleteNro = () => {
    

      if (type == 1) {

          setNroSocio(nroSocio.slice(0, -1));
      } else {

          if (iptNumeroWhatsapp.length >= 7) {


              const newPhoneNumber = iptNumeroWhatsapp.slice(0, -1);
              setIptNumeroWhatsapp(newPhoneNumber);
              set_IptNumeroWhatsapp(iptNumeroWhatsapp);



          }




      }






  }


  const downloadPdf = async (url, fileName) => {
      try {
          const downloadResumable = FileSystem.createDownloadResumable(
              url,
              FileSystem.documentDirectory + fileName
          );

      //    console.log('Downloading PDF...');

          const { uri } = await downloadResumable.downloadAsync();



          return uri;
      } catch (error) {
         // console.error('Error downloading PDF:', error);
          return null;
      }
  };




  const handleDownload = async () => {
      const pdfUrl = 'https://www.coovilros.com/resumensocios.aspx?nroresumen=NzU1ODgzMw==';
      const pdfFileName = 'comprobante.pdf';


      const uri = await downloadPdf(pdfUrl, pdfFileName);
      setPdfUri(uri);
  };


  const enviarWp = async () => {

      setLoading(true);

      const nro= iptNumeroWhatsapp.replace("+54 9 ", "").replace(" ", "");

      const response = await postWhatsApp(nro, pdfUri);

      if (response != false) {


 

      alert('WhatsApp enviado correctamente');

      }


      setLoading(false);

  }

  const limpiar = () => {

      setNroSocio('');
      setIptNumeroWhatsapp('+54 9 ');
      setType(1);


  }



  return (
   <>
            <View style={styles.container}>

                <View style={{ flex: 1, padding: 15 }}>

                {type === 1 ? (
                    <View style={{textAlign:'center', alignContent:'center', alignItems:'center', marginBottom:10}}>
                    <MyTextDynamic cl={"#2F3B49"} value={"INGRESE SU NÚMERO DE SOCIO"} fs={25} fw={'bold'}></MyTextDynamic>
                    </View>
                ):<></>}

                    <View style={{ flexDirection: 'row' }}>


                        <TouchableOpacity onPress={() => write(1)} style={styles.btnLeft1}>
                            <Text style={styles.btnText}>1</Text>
                        </TouchableOpacity>

                        <TouchableOpacity onPress={() => write(2)} style={styles.btnCenter1}>
                            <Text style={styles.btnText}>2</Text>
                        </TouchableOpacity>

                        <TouchableOpacity onPress={() => write(3)} style={styles.btnRight1}>
                            <Text style={styles.btnText}>3</Text>
                        </TouchableOpacity>

                    </View>


                    <View style={{ flexDirection: 'row' }}>

                        <TouchableOpacity onPress={() => write(4)} style={styles.btnLeft1}>
                            <Text style={styles.btnText}>4</Text>
                        </TouchableOpacity>

                        <TouchableOpacity onPress={() => write(5)} style={styles.btnCenter1}>
                            <Text style={styles.btnText}>5</Text>
                        </TouchableOpacity>

                        <TouchableOpacity onPress={() => write(6)} style={styles.btnRight1}>
                            <Text style={styles.btnText}>6</Text>
                        </TouchableOpacity>

                    </View>


                    <View style={{ flexDirection: 'row' }}>

                        <TouchableOpacity onPress={() => write(7)} style={styles.btnLeft}>
                            <Text style={styles.btnText}>7</Text>
                        </TouchableOpacity>

                        <TouchableOpacity onPress={() => write(8)} style={styles.btnCenter}>
                            <Text style={styles.btnText}>8</Text>
                        </TouchableOpacity>

                        <TouchableOpacity onPress={() => write(9)} style={styles.btnRight}>
                            <Text style={styles.btnText}>9</Text>
                        </TouchableOpacity>

                    </View>



                    <View style={{ flexDirection: 'row' }}>

                        <View style={styles.btnLeft2}></View>

                        <TouchableOpacity onPress={() => write(0)} style={styles.btnCenter2}>
                            <Text style={styles.btnText}>0</Text>
                        </TouchableOpacity>



                        <TouchableOpacity onPress={() => deleteNro()} style={styles.btnRight2}>
                            <Text style={styles.btnText}>{`<`}</Text>
                        </TouchableOpacity>


                    </View>






                    {type === 1 ? (
                        <View style={{ flexDirection: 'row', marginTop: 22 }}>

                            <View style={styles.btnNro}>

                                <Text style={styles.btnText}>{nroSocio}</Text>

                            </View>

                            <TouchableOpacity onPress={getPdf} style={styles.btnSearch}>
                                <Text style={styles.btnTextSearch}>Buscar</Text>
                            </TouchableOpacity>


                        </View>
                    ) :
                        (

                            <View style={{ flexDirection: 'row', marginTop: 22 }}>

                                <TouchableOpacity onPress={limpiar} style={styles.btnSearch}>
                                    <Text style={styles.btnTextSearch}>Limpiar</Text>
                                </TouchableOpacity>


                            </View>
                        )


                    }




                </View>





                {type === 2 ? (

                    <View style={{ flex: 1.5, padding: 15 }}>


                        <View style={styles.containerFrm}>
                            <View style={{ flex: 1.2 }}>


                                <MyInputWhatsApp
                                    title={'*Número de WhatsApp'}
                                    value={iptNumeroWhatsapp}
                                    placeHolder={"Escriba aquí"}
                                    onValueChange={set_IptNumeroWhatsapp}
                                    onBlur={() => {
                                        formatPhoneNumber(iptNumeroWhatsapp);
                                    }}
                                    inputType={'numeric'}
                                    isSuccess={successWhatsapp}

                                ></MyInputWhatsApp>

                            </View>
                            <View style={{ flex: 1, paddingTop: 5, paddingLeft: 15 }}>
                                <Boton onPress={enviarWp} cl={'#FFF'} bg={'#37AF68'} text={'Enviar WhatsApp'}></Boton>
                            </View>


                        </View>

                        <WebView
                            style={styles.pdfReader}
                            originWhitelist={['*']}
                            source={{ uri: pdfUri }}
                        />

                    </View>
                ) : (<></>)}





            </View>
            {loading && <MyLoading />}
        </>
    
  );
}


export default App;

const styles = StyleSheet.create({
  container: {
    flex: 1,
    flexDirection: 'row',
    padding: 10,
},
containerFrm: {
    flexDirection: 'row',
    marginBottom: 20
},
containerBtn: {
    flexDirection: 'row',
    padding: 10,
},
containerCarrousel: {
    flexDirection: 'row',
    // padding: 10,
},
containerCard: {
    flexDirection: 'row',
    padding: 10,
    marginTop: -20,
},
btnText: {
    fontWeight: '900',
    fontSize: 32,
    textAlign: 'center',
    color: '#37AF68',
},
btnTextSearch: {
    fontWeight: '900',
    fontSize: 20,
    textAlign: 'center',
    color: '#37AF68',
},


btnLeft1: {
    flex: 1,
    borderColor: '#37AF68',
    borderLeftWidth: 4,
    borderTopWidth: 4,
    padding: 10,
    alignItems: 'center',
    justifyContent: 'center',
    alignContent: 'center',
},

btnRight1: {
    flex: 1,
    borderColor: '#37AF68',
    borderRightWidth: 4,
    borderTopWidth: 4,
    padding: 10,
    alignItems: 'center',
    justifyContent: 'center',
    alignContent: 'center',
},

btnCenter1: {
    flex: 1,
    borderColor: '#37AF68',
    borderLeftWidth: 4,
    borderTopWidth: 4,
    borderRightWidth: 4,
    padding: 10,
    alignItems: 'center',
    justifyContent: 'center',
    alignContent: 'center',
},

btnLeft: {
    flex: 1,
    borderColor: '#37AF68',
    borderBottomWidth: 4,
    borderLeftWidth: 4,
    borderTopWidth: 4,
    padding: 10,
    alignItems: 'center',
    justifyContent: 'center',
    alignContent: 'center',
},

btnRight: {
    flex: 1,
    borderColor: '#37AF68',
    borderBottomWidth: 4,
    borderRightWidth: 4,
    borderTopWidth: 4,
    padding: 10,
    alignItems: 'center',
    justifyContent: 'center',
    alignContent: 'center',
},

btnCenter: {
    flex: 1,
    borderColor: '#37AF68',
    borderBottomWidth: 4,
    borderRightWidth: 4,
    borderWidth: 4,
    padding: 10,
    alignItems: 'center',
    justifyContent: 'center',
    alignContent: 'center',
},
btnLeft2: {
    flex: 1,
    borderColor: '#FFF',
    borderBottomWidth: 4,
    borderLeftWidth: 4,
    borderTopWidth: 4,
    padding: 10,
    alignItems: 'center',
    justifyContent: 'center',
    alignContent: 'center',
},
btnRight2: {
    flex: 1,
    borderColor: '#37AF68',
    borderBottomWidth: 4,
    borderRightWidth: 4,
    borderTopWidth: 0,
    padding: 10,
    alignItems: 'center',
    justifyContent: 'center',
    alignContent: 'center',
},
btnCenter2: {
    flex: 1,
    borderColor: '#37AF68',
    borderTopWidth: 0,
    borderBottomWidth: 4,
    borderRightWidth: 4,
    borderWidth: 4,
    padding: 10,
    alignItems: 'center',
    justifyContent: 'center',
    alignContent: 'center',
},
btnNro: {
    flex: 2.5,
    borderColor: '#000',
    borderWidth: 2,
    padding: 10,
    alignItems: 'center',
    justifyContent: 'center',
    alignContent: 'center',
},
btnSearch: {
    flex: 1,
    backgroundColor: '#000',
    borderColor: '#000',
    borderWidth: 2,
    padding: 10,
    alignItems: 'center',
    justifyContent: 'center',
    alignContent: 'center',
},
});
