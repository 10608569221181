import { Text,StyleSheet, View,ActivityIndicator } from 'react-native';

const  MyLoading =()=> {
    return (
        <View style={styles.loaderContainer}>
        <ActivityIndicator size="large" color="#133D24" />
        <Text style={styles.loadingText}>Espere por favor...</Text>
    </View>
    );
}

export default MyLoading;

const styles = StyleSheet.create({
    loaderContainer: {
        position: 'absolute',
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        backgroundColor: 'rgba(255, 255, 255, 1)',
        alignItems: 'center',
        justifyContent: 'center',
    },
    loadingText: {
        fontSize: 18,
        color: '#133D24',
       // fontFamily:'Gabarito',
        fontWeight: '600',
        marginTop: 20,
    },
})