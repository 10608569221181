import API_BASE_URL from "../../apiConfig";
import AsyncStorage from '@react-native-async-storage/async-storage';

const postSaveTokenPushAPI = async (nro,url) => {


    try {


    
      
const dataParams = {
  nroTelefono: nro,
  url: url
};



      const response = await fetch(`${API_BASE_URL}/CoovilrosResumen`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(dataParams),
      });


  
      if (!response.ok) {
        return false;
      }
  



      const data = await response.json();
      
      return data;
    } catch (error) {
      return false;
    }
  };
  
  export default postSaveTokenPushAPI;