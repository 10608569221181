import React, { useState, useEffect } from 'react';
import { View, Text, StyleSheet, TextInput } from 'react-native';
import { Ionicons } from '@expo/vector-icons'; 
const MyInputWhatsApp = ({ title,value, onValueChange,placeHolder,onBlur = () => {},  inputType = 'text', isSuccess = 0  }) => {
 
 
  const [textValue, setTextValue] = useState(value);



  const handleValueChange = (newValue) => {
    // Ejecuta la función de devolución de llamada cuando cambia el valor
  

    const formattedValue = `+54 9 ${newValue.slice(6)}`;
    setTextValue(formattedValue);
    onValueChange(formattedValue);
    // setTextValue(formattedValue);
    // onValueChange(formattedValue);
  };






  const handleBlur = () => {
    onBlur(textValue); // Puedes pasar el valor actual o cualquier otra lógica que desees aquí
  };
  


    // Establece el valor del estado local cuando el valor externo cambia
    useEffect(() => {
     
      setTextValue(value);
    }, [value]);




    

  return (
    <View >
    
    
    {isSuccess === 0 ? 
  <View style={styles.container}>
 

  <TextInput 
    style={styles.inputText} 
    placeholder={placeHolder}
    value={textValue} 
    onChangeText={handleValueChange}
    onBlur={handleBlur}
    keyboardType={inputType === 'numeric' ? 'numeric' : 'default'} 
    readOnly={true} 
  />
 
  
</View>
  : isSuccess === 1 ?
  <View style={styles.container1}>
 
    <TextInput 
      style={styles.inputText1} 
      placeholder={placeHolder}
      value={textValue} 
      onChangeText={handleValueChange}
      onBlur={handleBlur}
      keyboardType={inputType === 'numeric' ? 'numeric' : 'default'} 
      readOnly={true} 
    />
      
        <Ionicons name="close-circle-outline" size={25} color="#E74032" style={{ marginLeft:-15, marginTop:8 }} />
     
    
  </View>
    : isSuccess === 2 ?
    <View style={styles.container2}>
   
 
  <TextInput 
      style={styles.inputText2} 
      placeholder={placeHolder}
      value={textValue} 
      onChangeText={handleValueChange}
      onBlur={handleBlur}
      keyboardType={inputType === 'numeric' ? 'numeric' : 'default'} 
      readOnly={true} 
    />
    <Ionicons name="checkmark-circle-outline" size={25} color="#04AB1A" style={{ marginLeft:-15, marginTop:8 }} />

</View>
      : null  
}



    </View>
  );
};

const styles = StyleSheet.create({


  container: {
    width: '100%', // Ajusta para ocupar el 100% del ancho disponible
    backgroundColor:'#F5F5F6',
    borderRadius: 10,
    paddingHorizontal:15.5,
    marginTop: 3,
    height:44,
  },
  inputContainer: {
    
    
    flexDirection: 'row',
  
  },

  inputText:{
    height:44,
    fontWeight:'900',
    fontSize: 14, 
    color:'#2F3B49',
    
   
  },



  label: {
     
    color: '#2F3B49',
    position: 'absolute',
   
    paddingHorizontal: 15,
    paddingTop:5,

    borderTopLeftRadius: 10,
    borderTopRightRadius: 10,
    width:'100%',
    zIndex: 1,
    backgroundColor: '#F5F5F6',
    fontSize: 12
},
container1: {
  width: '100%', // Ajusta para ocupar el 100% del ancho disponible
  backgroundColor:'#F5F5F6',
  borderRadius: 10,
  paddingHorizontal:15.5,
  marginTop: 3,
  height:44,
  
  flexDirection: 'row',
},
inputText1:{
  height:44,
  width:'100%',
  fontWeight:'900',
  fontSize: 14, 
  color:'#E74032',
  
 
},

label1:{
  color: '#E74032',
  position: 'absolute',
 
  paddingHorizontal: 15,
  paddingTop:5,

  borderTopLeftRadius: 10,
  borderTopRightRadius: 10,
  width:'100%',
  zIndex: 1,
  fontSize: 12

},


container2: {
  

  width: '100%', // Ajusta para ocupar el 100% del ancho disponible
  backgroundColor:'#F5F5F6',
  borderRadius: 10,
  paddingHorizontal:15.5,
  marginTop: 3,
  height:44,
  
  flexDirection: 'row',

},

inputText2:{
  height:44,
  width:'100%',
  fontWeight:'900',
  fontSize: 14, 
  color:'#04AB1A',
  
 
},
label2:{
color: '#04AB1A',
  position: 'absolute',
 
  paddingHorizontal: 15,
  paddingTop:5,

  borderTopLeftRadius: 10,
  borderTopRightRadius: 10,
  width:'100%',
  zIndex: 1,
  fontSize: 12
}

});

export default MyInputWhatsApp;
