import { Text,StyleSheet, View } from 'react-native';

const  MyTextDynamic =({value,cl,fs,fw, fm}) => {
    return (
        <View style={styles.container}>
            <Text style={{color:cl, fontSize:fs, fontFamily:fm, fontWeight:fw, }}>{value}</Text>
        </View>
    );
}

export default MyTextDynamic;

const styles = StyleSheet.create({
    container: {
        paddingLeft:5
    }
})